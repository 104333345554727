<template>
    <div class="outerContainer">
        <div class="cardOuterContainer">
            <img class="productHeroImage" v-bind:src=image />
            <div class="projectDescriptionPart">
                <h3>{{ projectName }}</h3>
                <div class="chainContainer">
                    <img class="chainLogo" v-bind:src=chainLogo> {{chain}}
                </div>
                <a v-bind:href=link target="_blank" class="mainLink">Visit Homepage</a>
                <div class="buttons" v-if="buttons">
                    <div v-for="(button, i) in buttons" :key="i">
                        <a v-bind:href=button.link target="_blank">{{ button['title'] }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectCard',
    props: {
        projectName: String,
        image: String,
        chain: String,
        chainLogo: String,
        link: String,
        buttons: Array
    }
}
</script>

<style scoped>

.outerContainer {
    display: block;
    margin-bottom: 16px;
}
.cardOuterContainer {
    width: 640px;
    overflow: hidden;
    display: inline-block;
    border-radius: 10px;
    backdrop-filter: blur(25px) brightness(1.2) saturate(1.2);
    text-align: left;

}

.chainContainer {
    display: flex;
    align-items: center;
}

.chainLogo {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    
}

.cardOuterContainer .productHeroImage {
    width: 100%;
}

.projectDescriptionPart {
    padding: 16px 32px 32px 32px;
}

a,
a:hover,
a:visited {
    text-decoration: none;
    color: white;
}

.mainLink, .buttons, .buttons div {
    display: inline-block;
}

.mainLink, .buttons div {
    margin-right: 16px;
}

.projectDescriptionPart a {
    margin-top: 12px;
    display: inline-block;
    padding: 4px 16px;
    /* background: #1C62AA; */
    border-radius: 4px;
    background: rgba(0,0,0,0.1);
}

font-awesome-icon {
    margin-left: 6px;
}

@media screen and (max-width: 640px){
    .cardOuterContainer {
        width: 90%;
    }
}
</style>
<template>
  <header>
        <title>0xA2M</title>
    </header>
  <h2>0xA2M</h2>
  <div v-for="(v, i) in data" :key="i">
    <ProjectCard 
      :projectName=v.projectName 
      :image=v.image 
      :chain=v.chain 
      :link=v.link 
      :buttons=v.buttons
      :chainLogo=v.chainLogo />
  </div>
</template>

<script>
import ProjectCard from './components/ProjectCard.vue'

export default {
  name: 'App',
  components: {
    ProjectCard
  },
  data() {
    return {
      data: [
        {
          projectName: "FengLi Candy Machine",
          image: require('@/assets/fengli.jpg'),
          chain: "Solana",
          link: "https://fengli-candy-machine-ui.vercel.app",
          chainLogo: require('@/assets/sol.svg'),
          buttons: [
            {
              "title": "OpenSea",
              "link": "https://opensea.io/collection/taiwan-fengli"
            }
          ]
        },
        {
          projectName: "EGG TW",
          image: require('@/assets/eggtw.jpg'),
          chain: "Polygon",
          link: "https://eggtw-portal.vercel.app/",
          chainLogo: require('@/assets/polygon.svg'),
          buttons: [
            {
              "title": "Uniswap",
              "link": "https://app.uniswap.org/#/tokens/polygon/0x53006673b5c87263817260Ce9CA9eB71Abbe9b50"
            },
            {
              "title": "Twitter",
              "link": "https://twitter.com/eggtw_official"
            }
          ]
        }
      ]
    }
  }
}
</script>

<style>
body {
  background: #2C3333;
  color: white;
  background-image: url('~assets/bg3.jpeg');
  background-size: cover;
  background-attachment: fixed;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}
</style>
